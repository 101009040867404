<template>
  <Loading v-if="loadingUser"/>
  <div class="background" id="twofactor-background" v-else>
    <div class="dark-overlay" id="twofactor-overlay">
      <v-card class="modal" style="text-align: left">
        <v-card-title class="headline" id="twofactor-headline">
            <img
              src="../../assets/buzz-icon-yellow.svg"
              alt="Buzz Solutions"
              class="buzz-logo"
              id="buzz-logo-img"
            >
            Buzz Solutions
        </v-card-title>
        <v-card-title style="color: grey; justify-content: center" id="twofactor-title">
          Two Factor Authentication
        </v-card-title>

        <span v-if="!authType">
          <v-card-text style="text-align: left">
            <div style="text-align: center" id="select-2fa-method">Select method</div>
              <p class="text--primary text-center" v-if="noPhoneNumber">
                <span class="font-weight-regular red--text text-sm-caption" id="no-phone-message">
                  No phone number is provided. Authentication by SMS is disabled
                </span>
              </p>
            <v-radio-group
              class="authtype-radio-group"
              v-model="authType"
              row
              id="twofactor-select-group"
            >
              <v-radio
                label="Phone"
                value="phone"
                id="select-sms"
                :disabled="noPhoneNumber"
              ></v-radio>
              <v-radio
                label="Email"
                id="select-email"
                value="email"
              ></v-radio>
              <v-radio
                v-if="hasBackdoorAccess"
                id="select-backdoor-access"
                label="Backdoor Code"
                value="backdoor"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </span>

        <span v-else>
          <div id="backdoor" v-if="authType === 'backdoor'">
            <v-card-subtitle class="subtitle" id="backdoor-access-instructions">
              Please enter the 6 digit code given to you by your provider.
            </v-card-subtitle>
          </div>
          <div id="no-backdoor" v-else>
            <v-card-title class="subtitle" id="no-backdoor-message">
            Sending code to: {{
              formatContact(
                (authType === 'phone') ? currentUser.phone : currentUser.email,
              )
            }}
            </v-card-title>
            <v-card-subtitle class="subtitle" id="verification-code-time-limit">
              Your code will only be active for 10 minutes
            </v-card-subtitle>
          </div>
          <TwoFactorForm
            :authType="authType"
            :loadingSid="loadingSid"
            @resend="resendCode"
            @authenticate="twoFactorAuthenticate"
            @create="sendCode"
            @clear="clearState"
          />
        </span>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import split from 'lodash/split';
import Loading from '@components/common/Loading.vue';
import TwoFactorForm from '@views/authentication/TwoFactorForm.vue';

export default {
  name: 'TwoFactor',
  components: {
    Loading,
    TwoFactorForm,
  },
  data: () => ({
    authType: '',
    email: '',
    phoneNumber: '',
    backdoor: false,
    sid: '',
  }),
  methods: {
    ...mapActions(['setCurrentUser', 'getCompany', 'verifyTwoFactorAuth', 'createVerification', 'updateVerification', 'getBackdoorAccess', 'clearAuthenticationErrors', 'logout']),
    /*
      |---------------------------------------------------|
      |   @method   formatContact                         |
      |   @params   contact <String>                      |
      |   @desc     Depending on the 2FA method the user  |
      |             chooses (email or sms), the user's    |
      |             email will be returned or a formatted |
      |             version of the user's phone number    |
      |---------------------------------------------------|
    */
    formatContact(contact) {
      if (this.authType === 'email') return contact;

      const sections = split(this.currentUser.phone, '-');
      return sections.length > 1
        ? `(***)-***-${sections[sections.length - 1]}` // usa format
        : this.currentUser.phone
          .replace(/\d/g, '*')
          .slice(0, this.currentUser.phone.length - 4) + this.currentUser.phone.slice(-4);
    },

    /*
      |---------------------------------------------------|
      |   @method   cancelVerification                    |
      |   @params   <Void>                                |
      |   @desc     Cancels the verification under the    |
      |             specified SID. This method will be    |
      |             used when a user navigates away from  |
      |             TwoFactorForm or when a user clicks   |
      |             the 'Resend Code' button              |
      |---------------------------------------------------|
    */
    async cancelVerification() {
      try {
        const payloadUpdate = {
          sid: this.sid,
          verification: 'two-factor',
        };
        const res = await this.updateVerification(payloadUpdate);
        return res;
      } catch (err) {
        return err;
      }
    },
    /*
      |---------------------------------------------------|
      |   @method   sendCode                              |
      |   @params   <Void>                                |
      |   @desc     When TwoFactorForm has been rendered  |
      |             it will create a new verification and |
      |             send the code to the user via sms or  |
      |             email. This method is also re-used    |
      |             within the `resendCode` method        |
      |---------------------------------------------------|
    */
    async sendCode() {
      // Set payload to make a call to endpoint /api/auth/:verificationType/:uid
      // with request body of { channel }
      const payload = {
        uid: this.currentUser.uid,
        channel: this.authType === 'phone' ? 'sms' : 'email',
        verification: 'two-factor',
      };

      try {
        const res = await this.createVerification(payload);
        if (res.status === 200) this.sid = res.data.sid;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error sending 2FA code: ', err);
      }
    },

    /*
      |-----------------------------------------------|
      |   @method   twoFactorAuthenticate             |
      |   @params   code <String>                     |
      |   @desc     Retrieves the user input of the   |
      |             2FA code and sends it to the      |
      |             backend to verify it              |
      |-----------------------------------------------|
    */
    async twoFactorAuthenticate(code) {
      const payload = {
        uid: this.currentUser.uid,
        code,
        verification: 'two-factor',
        sid: this.sid,
        authType: this.authType,
      };

      // Verify the code
      try {
        const res = await this.verifyTwoFactorAuth(payload);

        // If response has a status of 200, get company from user's company document
        if (res.status === 200) {
          const { data } = await this.getCompany(this.currentUser.cid);

          this.$nextTick(() => {
            this.sid = '';
            if (data.config?.powerGuardEnabled) {
              this.$router.push('/app');
            } else {
              this.$router.push('/projects/view/grid');
            }
          });
        }
      // eslint-disable-next-line no-console
      } catch (err) { console.log(err); }
    },
    /*
      |-----------------------------------------------|
      |   @method   resendCode                        |
      |   @params   <Void>                            |
      |   @desc     Cancels the verification under the|
      |             specified SID, then sends out a   |
      |             new 2FA code                      |
      |-----------------------------------------------|
    */
    async resendCode() {
      try {
        const res = await this.cancelVerification();

        if (res.status === 200) await this.sendCode();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('error: ', err);
      }
    },

    /*
      |-----------------------------------------------|
      |   @method   clearState                        |
      |   @params   <Void>                            |
      |   @desc     Cancels the verification under    |
      |             the specified SID when a user     |
      |             leaves the page                   |
      |-----------------------------------------------|
    */
    async clearState() {
      try {
        const res = await this.cancelVerification();
        if (res.status === 200) this.sid = '';
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error clearing state: ', err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loggedIn', 'loadingUser', 'hasBackdoorAccess']),
    noPhoneNumber() {
      if (!this.currentUser) return false;
      return !this.currentUser.phone;
    },
    showBackdoorCodeCheckbox() {
      return Object.keys(this.currentUser).includes('backDoorAccess') && this.currentUser.backDoorAccess;
    },
    loadingSid() {
      return this.sid === '';
    },
  },
  async created() {
    if (this.loggedIn) return;

    // let user;

    const { uid } = this.$router.history.current.params;

    try {
      if (!this.currentUser) await this.setCurrentUser(uid);

      // const hasBackdoorAccess = await this.getBackdoorAccess(uid);
      // if (!hasBackdoorAccess) {
      //   this.phoneNumber = user.phone;
      //   this.email = user.email;
      //   this.authType = this.$route.query.authType;
      // }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error finding user: ', err);
      this.logout();
    }
  },
  watch: {
    authType(val) {
      if (val) {
        if (this.$route.query.authType !== val) {
          this.$router.push({
            query: { ...this.$route.query, authType: val },
          });
        }
      }
    },
    $route(route) {
      this.authType = route.query.authType;
    },
  },
};
</script>

<style>
.authtype-radio-group .v-input--radio-group__input  {
  justify-content: center;
}
</style>

<style scoped>
.background {
  text-align: center;
  width: 100%;
  position: relative;
  background: url('../../assets/washed-out-wires.png') no-repeat;
  background-size: cover;
  height: calc(100vh - 60px);
  margin-bottom: -50px;
  -o-backgorund-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}
.dark-overlay {
  background-color: rgb(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 500px;
}
.headline {
  justify-content: center;
  /* color: grey; */
  font-weight: 500;
}
.buzz-logo {
  width: 75px;
  margin-right: 10px;
}
</style>
